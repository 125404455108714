import {
  ActivityIndicator,
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import Heading from "../Heading/index.web";
import IconButton from "../Buttons/IconButton";
import useTwilio from "../../hooks/useTwilio";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import useToggle from "../../hooks/useToggle";
import DialerPanel from "../Dialer/Panel/index.web";
import KeypadPanel from "../../modals/KeypadModal/Panel/index.web";
import { parsePhoneNumber } from "libphonenumber-js";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faGear,
  faGrid,
  faMicrophoneSlash,
  faPhoneHangup,
  faUser,
  faCircleUser,
  faPhone,
  faCommentLines,
  faShare,
  faNote,
  faUserPlus,
  faMoon,
} from "@fortawesome/pro-solid-svg-icons";
import { Switch, TouchableWithoutFeedback } from "react-native-web";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { useEffect } from "react";
import ConversationContext from "../../contexts/ConversationContext";
import NumbersContext from "../../contexts/NumbersContext";
import StyledInput from "../StyledInput";
import Engage from "../../services/engage";
import { CallsContext } from "../../contexts/CallsContext";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import SolidButton from "../Buttons/SolidButton";
import { CAMPAIGN_STATUS_NOT_SUBMITTED } from "../../constants/businessInformation";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import OutsidePressHandler from "react-native-outside-press";
import { OtherCallsContainer } from "./OtherCallsContainer/index.web";
import { getOffset } from "../../helpers/app";

export default function TopBar() {
  return (
    <View style={styles.top_bar_container}>
      <Col size={2}>
        <View
          center
          style={{
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            paddingVertical: 8,
          }}
        >
          <Image
            source={require("~/assets/logo-icon-trans.png")}
            style={[styles.side_panel__logo]}
          />
          <Heading
            size={5}
            color={palette.white}
            textStyle={{ fontWeight: "400", letterSpacing: 1 }}
            style={{
              paddingLeft: 8,
              height: 30,
              borderLeftWidth: 1,
              borderColor: palette.light_grey,
              justifyContent: "center",
            }}
            ml={12}
            mt={0}
            mb={0}
          >
            ENGAGE
          </Heading>
        </View>
      </Col>
      <Col>
        <Row
          style={{
            zIndex: 200,
            gap: 8,
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CallsCol />
          <OtherCallsContainer />
        </Row>
      </Col>
      <NavItems />
    </View>
  );
}

const NavItems = () => {
  const [activePanel, setActivePanel] = useState();
  const { activeCall } = useTwilio();
  const { clearConversation, setNewConversation } =
    useContext(ConversationContext);
  const [right, setRight] = useState(8);
  const dndRef = useRef(null);

  const {
    setBusinessModelVisible,
    campaignStatus,
    isManagerRole,
    isDisturbMode,
    loading,
    saving,
    updateSettings,
  } = useContext(UserContext);

  const handleUpdateDoNotDisturb = async (v) => {
    if (loading) return;

    await updateSettings({
      do_not_disturb: v,
    });
    if (!v) setActivePanel(null);
  };

  const history = useHistory();
  const isSettingsScreen = history?.location?.pathname?.includes("/settings");

  const onNewCustomerPress = () => {
    history.push({ hash: "new-customer" });
  };

  const changePanel = (newPanel) => () => {
    setActivePanel((panel) => {
      if (panel === newPanel) return undefined;
      return newPanel;
    });
  };

  useEffect(() => {
    if (activeCall) setActivePanel();
  }, [activeCall]);

  useEffect(() => {
    if (activePanel === "dnd") {
      try {
        const dndRectangle = getOffset(dndRef.current);
        const { right } = dndRectangle;
        setRight(window?.innerWidth - right + 150);
      } catch {
        setRight(8);
      }
    } else {
      setRight(8);
    }
  }, [activePanel, dndRef.current]);

  return (
    <>
      <Col size={2}>
        <Row
          center
          pt={8}
          pb={8}
          style={{ justifyContent: "flex-end", height: "100%" }}
        >
          <View
            style={{
              height: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
              gap: 32,
              marginRight: 24,
            }}
          >
            {campaignStatus === CAMPAIGN_STATUS_NOT_SUBMITTED &&
              isManagerRole() && (
                <TouchableWithoutFeedback
                  onPress={() => {
                    setActivePanel((activePanel) =>
                      activePanel === "alerts" ? undefined : "alerts",
                    );
                  }}
                  nativeID="top_bar_alert_icon"
                >
                  <View style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      color={palette.white}
                      secondaryColor={palette.red}
                      secondaryOpacity={1}
                      size={24}
                    />
                  </View>
                </TouchableWithoutFeedback>
              )}
            {isDisturbMode && !loading && (
              <TouchableWithoutFeedback
                onPress={changePanel("dnd")}
                nativeID="top_bar_dnd_mode_on"
              >
                <View style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faMoon}
                    color={palette.white}
                    size={20}
                  />
                </View>
              </TouchableWithoutFeedback>
            )}
            <TouchableWithoutFeedback
              onPress={onNewCustomerPress}
              nativeID="top_bar_create_customer"
            >
              <View style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faUserPlus}
                  color={palette.white}
                  size={24}
                />
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback
              onPress={changePanel("dialer")}
              nativeID="top_bar_phone_icon"
            >
              <View style={{ cursor: activeCall ? "not-allowed" : "pointer" }}>
                <FontAwesomeIcon
                  icon={faPhone}
                  color={activeCall ? palette.light_grey : palette.white}
                  size={18}
                />
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback
              onPress={() => {
                setNewConversation(true);
                clearConversation();
                if (isSettingsScreen) history.replace("/");
              }}
              nativeID="top_bar_new_message_icon"
            >
              <View style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faCommentLines}
                  color={palette.white}
                  size={18}
                />
              </View>
            </TouchableWithoutFeedback>
            {!isSettingsScreen && (
              <TouchableWithoutFeedback
                onPress={() => history.replace("/settings")}
                nativeID="top_bar_settings_icon"
              >
                <View style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon
                    icon={faGear}
                    color={palette.white}
                    size={18}
                  />
                </View>
              </TouchableWithoutFeedback>
            )}
            <TouchableWithoutFeedback
              onPress={changePanel("user")}
              nativeID="top_bar_user_icon"
            >
              <View
                style={{
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                  cursor: "pointer",
                }}
                ref={dndRef}
              >
                <FontAwesomeIcon
                  icon={faCircleUser}
                  color={palette.white}
                  size={18}
                />
                <FontAwesomeIcon
                  icon={activePanel === "user" ? faCaretUp : faCaretDown}
                  color={palette.white}
                  size={14}
                />
              </View>
            </TouchableWithoutFeedback>
          </View>
        </Row>
      </Col>
      {activePanel && (
        <View
          style={{
            backgroundColor: palette.white,
            zIndex: 200,
            position: "absolute",
            top: "110%",
            right: right,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: palette.light_grey,
            minWidth: 150,
            overflow: "hidden",
            paddingVertical: 4,
            transition: "right 0.3s",
          }}
        >
          {activePanel === "user" && (
            <OutsidePressHandler onOutsidePress={() => setActivePanel(null)}>
              <Option
                label="Open Webapp"
                onPress={() => {
                  const webappPostMessageEvent =
                    WebAppPostMessage.createCloseEngageEvent();
                  webappPostMessageEvent.emitEvent();
                  setActivePanel(() => null);
                }}
              />
              <Option
                label="Logout"
                onPress={() => history.replace("/logout")}
              />
            </OutsidePressHandler>
          )}
          {activePanel === "dialer" && (
            <OutsidePressHandler onOutsidePress={() => setActivePanel(null)}>
              <View style={{ padding: 8 }}>
                <DialerPanel />
              </View>
            </OutsidePressHandler>
          )}
          {activePanel === "dnd" && !loading && (
            <OutsidePressHandler onOutsidePress={() => setActivePanel(null)}>
              <View
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  cursor: "pointer",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <Heading size={6} mb={0} mt={0}>
                  Do Not Disturb
                </Heading>
                <Col size={2} pl={4}>
                  <Switch
                    value={isDisturbMode}
                    onValueChange={handleUpdateDoNotDisturb}
                    disabled={saving}
                  />
                </Col>
              </View>
            </OutsidePressHandler>
          )}
          {activePanel === "alerts" && (
            <OutsidePressHandler onOutsidePress={() => setActivePanel(null)}>
              <Option
                label={`Your A2P campaign form is pending,\n Click here to fill details.`}
                onPress={() => {
                  setBusinessModelVisible(true);
                  setActivePanel(() => null);
                }}
              />
            </OutsidePressHandler>
          )}
        </View>
      )}
    </>
  );
};

export const Option = ({
  onPress,
  label,
  bold = true,
  paddingVertical = 8,
}) => {
  return (
    <TouchableWithoutFeedback onPress={onPress} nativeID={`option-${label}`}>
      <View
        style={{
          paddingHorizontal: 16,
          paddingVertical: paddingVertical,
          cursor: "pointer",
        }}
      >
        <Heading
          size={6}
          mb={0}
          mt={0}
          textStyle={{
            fontWeight: bold ? "600" : "400",
            fontFamily: bold ? "OpenSans_600SemiBold" : "OpenSans_400Regular",
          }}
        >
          {label}
        </Heading>
      </View>
    </TouchableWithoutFeedback>
  );
};

const CallsCol = () => {
  const {
    activeCall,
    startCall,
    endCall,
    toggleMute,
    isMuted,
    callStatus,
    callDuration,
    destination,
    isOnHold,
    toggleHold,
  } = useTwilio();

  const [dialPadVisible, toggleDialPad] = useToggle(false);
  const [activePanel, setActivePanel] = useState();
  const { getConversationByParticipant } = useContext(ConversationContext);
  const { getNumbers } = useContext(NumbersContext);
  const [callIsTransferred, setCallIsTransferred] = useState({});
  const { user, canSeeCustomerContact } = useContext(UserContext);
  const { numbers, loading: LoadingNumbers } = useContext(NumbersContext);
  const { activeCallId } = useContext(CallsContext);

  const onMute = () => {
    toggleMute(!isMuted);
  };

  const visible = useMemo(() => activeCall, [activeCall]);

  const participant = useMemo(() => {
    let res = {};
    if (destination?.participant?.name) {
      res.name = destination?.participant?.name;
    }

    if (destination?.phoneNumber) {
      res.phone_number = destination?.phoneNumber;
    }

    if (destination?.participant?.id) {
      res.participant_id = destination?.participant?.id;
    }

    if (!res?.phone_number && activeCall?.parameters?.From) {
      res.phone_number = activeCall?.parameters?.From;
    }

    if (destination?.phoneNumber && !!destination?.participant?.remote_id) {
      res.customer_id = destination?.participant?.remote_id;
    }

    if (
      activeCall?.customParameters &&
      activeCall?.customParameters.has("participantName")
    ) {
      res.name = activeCall?.customParameters?.get("participantName");
    }

    if (!res.name && activeCall) {
      res.name = "Unknown Caller";
    }

    if (
      !res.customer_id &&
      activeCall?.customParameters?.has("participantRemoteId")
    ) {
      res.customer_id = activeCall?.customParameters?.get(
        "participantRemoteId",
      );
    }

    if (
      activeCall?.customParameters &&
      activeCall?.customParameters.has("leadSourceId")
    ) {
      res.lead_source_id = activeCall.customParameters?.get("leadSourceId");
    }

    if (
      activeCall?.customParameters &&
      activeCall?.customParameters.has("leadSourceName")
    ) {
      res.lead_source_name = activeCall.customParameters?.get("leadSourceName");
    }

    if (
      !res?.participant_id &&
      activeCall?.customParameters?.has("participantId")
    ) {
      res.participant_id = activeCall?.customParameters?.get("participantId");
    }

    return res;
  }, [destination, activeCall]);

  const onOpenProfile = () => {
    if (!participant || !participant?.customer_id) return;
    getConversationByParticipant({
      id: participant?.participant_id,
      remote_id: participant?.customer_id,
    });
  };

  const phoneNumber = useMemo(() => {
    if (participant?.phone_number) {
      let res = "";
      try {
        const parsedNumber = parsePhoneNumber(
          participant.phone_number,
          user?.account?.country || "US",
        );
        res = parsedNumber.number;
      } catch (e) {
        console.log(e);
      }
      return res;
    }
    return "";
  }, [participant, user?.account?.country]);

  const onCallTransfer = useCallback(
    async (line) => {
      if (!isMuted) {
        onMute();
      }
      setActivePanel(null);
      setCallIsTransferred({
        call_sid: activeCall?.parameters?.CallSid,
        to: line?.friendly_name,
      });
      try {
        let params = {};
        const isOutgoing = activeCall?.direction !== "INCOMING";
        if (isOutgoing) {
          params = {
            To: line?.phone_number,
            CallSid: activeCall?.parameters?.CallSid,
            from: activeCall?.customParameters?.get("from"),
          };
        } else {
          params = {
            transfer_to: line?.phone_number,
            call_sid: activeCall?.parameters?.CallSid,
            account_id: user?.account?.id,
          };
        }
        const res = await Engage.transferCall(params, isOutgoing);
        if (!res || res?.error) {
          setCallIsTransferred({});
        }
      } catch (e) {
        setCallIsTransferred({});
      }
    },
    [activeCall?.parameters, user, isMuted],
  );

  const callHasCorrectCallSid = useMemo(() => {
    if (activeCall?.direction === "INCOMING") return true;
    else return activeCall?.parameters?.CallSid?.startsWith("CA");
  }, [activeCall?.parameters?.CallSid]);

  const PhoneLineInUse = useMemo(() => {
    if (activeCall) {
      if (destination?.phoneNumber) {
        return activeCall?.customParameters?.get("from");
      } else if (activeCall?.customParameters?.has("numberId")) {
        return activeCall?.customParameters?.get("numberId");
      }
    }
    return null;
  }, [activeCall, destination]);

  const filteredNumbers = useMemo(() => {
    if (!LoadingNumbers && PhoneLineInUse) {
      let matchField = "id";
      if (destination?.phoneNumber) {
        matchField = "phone_number";
      }

      return numbers?.filter((number) => number[matchField] !== PhoneLineInUse);
    }
    return [];
  }, [numbers, LoadingNumbers, PhoneLineInUse, destination, user]);

  if (!visible) return null;

  return (
    <View
      style={{
        backgroundColor: palette.grey_blue,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        paddingHorizontal: 16,
        height: "90%",
        justifyContent: "space-evenly",
        flexDirection: "row",
        gap: 16,
        minWidth: 400,
        maxWidth: 600,
      }}
    >
      {!!callIsTransferred?.to &&
      callIsTransferred?.call_sid === activeCall?.parameters?.CallSid ? (
        <>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading
              size={5}
              color={palette.white}
              mb={0}
              mt={0}
              numberOfLines={1}
              style={{
                maxWidth: 400,
              }}
            >
              This call has been transferred to {callIsTransferred?.to}
            </Heading>
          </View>
        </>
      ) : (
        <>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading size={6} color={palette.white} mb={0} mt={0}>
              Live Call
            </Heading>
            <Text style={{ color: palette.light_grey, fontSize: 12 }}>
              {callStatus || callDuration}
            </Text>
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              zIndex: 200,
            }}
          >
            <Row
              center
              style={{ zIndex: 200, gap: 8, justifyContent: "space-evenly" }}
              pb={2}
            >
              <IconTextButton
                iconProps={{
                  icon: faPhoneHangup,
                  color: palette.white,
                }}
                text="End"
                onPress={endCall}
                containerProps={{
                  width: 32,
                  height: 32,
                  backgroundColor: palette.danger,
                  borderRadius: 16,
                  paddingBottom: 4,
                  marginTop: 4,
                }}
                iconSize={16}
              />
              {/* <IconTextButton
              iconProps={{
                icon: faPause,
                color: isOnHold ? palette.black : palette.white,
              }}
              text={isOnHold ? "Resume" : "Hold"}
              onPress={toggleHold}
              containerProps={{
                width: 32,
                height: 32,
                backgroundColor: isOnHold ? palette.white : palette.black,
                borderRadius: 16,
                opacity: 0.8,
                paddingBottom: 4,
                marginTop: 4,
              }}
              iconSize={16}
            /> */}
              <IconTextButton
                iconProps={{
                  icon: faMicrophoneSlash,
                  color: isMuted ? palette.black : palette.white,
                }}
                text={isMuted ? "Unmute" : "Mute"}
                onPress={onMute}
                containerProps={{
                  width: 32,
                  height: 32,
                  backgroundColor: isMuted ? palette.white : palette.black,
                  borderRadius: 16,
                  opacity: 0.8,
                  paddingBottom: 4,
                  marginTop: 4,
                }}
                iconSize={16}
              />
              {!!activeCallId && (
                <IconTextButton
                  iconProps={{
                    icon: faNote,
                    color:
                      activePanel === "note" ? palette.black : palette.white,
                  }}
                  text={"Note"}
                  onPress={() => {
                    setActivePanel((active) =>
                      active === "note" ? null : "note",
                    );
                  }}
                  containerProps={{
                    width: 32,
                    height: 32,
                    backgroundColor:
                      activePanel === "note" ? palette.white : palette.black,
                    borderRadius: 16,
                    opacity: 0.8,
                    paddingBottom: 4,
                    marginTop: 4,
                  }}
                  iconSize={16}
                />
              )}
              {!LoadingNumbers &&
                filteredNumbers &&
                filteredNumbers?.length > 0 &&
                callHasCorrectCallSid && (
                  <IconTextButton
                    iconProps={{
                      icon: faShare,
                      color:
                        activePanel === "transfer"
                          ? palette.black
                          : palette.white,
                    }}
                    text={"Transfer"}
                    onPress={() => {
                      setActivePanel((active) =>
                        active === "transfer" ? null : "transfer",
                      );
                    }}
                    containerProps={{
                      width: 32,
                      height: 32,
                      backgroundColor:
                        activePanel === "transfer"
                          ? palette.white
                          : palette.black,
                      borderRadius: 16,
                      opacity: 0.8,
                      paddingBottom: 4,
                      marginTop: 4,
                    }}
                    iconSize={16}
                  />
                )}
              <IconTextButton
                iconProps={{
                  icon: faGrid,
                  color:
                    activePanel === "dialer" ? palette.black : palette.white,
                }}
                text="Dialpad"
                onPress={() => {
                  setActivePanel((active) =>
                    active === "dialer" ? null : "dialer",
                  );
                }}
                containerProps={{
                  width: 32,
                  height: 32,
                  backgroundColor:
                    activePanel === "dialer" ? palette.white : palette.black,
                  borderRadius: 16,
                  opacity: 0.8,
                  paddingBottom: 4,
                  marginTop: 4,
                }}
                iconSize={16}
              />
              {participant?.customer_id && (
                <IconTextButton
                  iconProps={{
                    icon: faUser,
                    color:
                      activePanel === "new-customer"
                        ? palette.black
                        : palette.white,
                  }}
                  text="Customer"
                  onPress={onOpenProfile}
                  containerProps={{
                    width: 32,
                    height: 32,
                    backgroundColor:
                      activePanel === "new-customer"
                        ? palette.white
                        : palette.black,
                    borderRadius: 16,
                    opacity: 0.8,
                    paddingBottom: 4,
                    marginTop: 4,
                  }}
                  iconSize={16}
                />
              )}
            </Row>
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading
              size={6}
              color={palette.white}
              mb={0}
              mt={0}
              textStyle={{
                fontWeight: "600",
                fontSize: 12,
                fontFamily: "OpenSans_600SemiBold",
              }}
              numberOfLines={1}
              style={{
                maxWidth: 120,
              }}
            >
              {participant?.customer_id ? participant?.name : "Unknown Caller"}
            </Heading>
            {canSeeCustomerContact && (
              <Text style={{ color: palette.white, fontSize: 10 }}>
                {phoneNumber}
              </Text>
            )}
          </View>
        </>
      )}
      {activePanel === "dialer" && (
        <View
          style={{
            backgroundColor: palette.white,
            zIndex: 200,
            position: "absolute",
            top: "120%",
            borderRadius: 4,
            borderWidth: 1,
            borderColor: palette.light_grey,
            maxWidth: 350,
            overflow: "hidden",
          }}
        >
          <KeypadPanel float />
        </View>
      )}
      {activePanel === "new-customer" && (
        <View
          style={{
            backgroundColor: palette.bg_light_grey,
            zIndex: 200,
            position: "absolute",
            top: "120%",
            left: "50%",
            transform: "translateX(20%)",
            borderRadius: 4,
            borderWidth: 1,
            borderColor: palette.light_grey,
            overflow: "hidden",
            minWidth: 225,
          }}
        >
          <Option label="Create New Customer" onPress={() => {}} />
          <Option label="Match Existing Customer" onPress={() => {}} />
        </View>
      )}
      {activePanel === "transfer" && (
        <TransferPanel
          onCallTransfer={onCallTransfer}
          filteredNumbers={filteredNumbers}
        />
      )}
      {activePanel === "note" && (
        <NotePanel
          onClose={() => {
            setActivePanel("");
          }}
        />
      )}
    </View>
  );
};

const TransferPanel = ({ onCallTransfer, filteredNumbers }) => {
  const { loading: LoadingNumbers } = useContext(NumbersContext);

  const [searchText, setSearchText] = useState("");

  const filterNumbersBySearch = useMemo(() => {
    if (searchText && searchText?.trim()) {
      return filteredNumbers?.filter(
        (number) =>
          number?.phone_number?.includes(searchText) ||
          number?.friendly_name?.includes(searchText),
      );
    } else return filteredNumbers;
  }, [filteredNumbers, searchText]);

  return (
    <View
      style={{
        backgroundColor: palette.white,
        zIndex: 200,
        position: "absolute",
        top: "120%",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: palette.light_grey,
        maxWidth: 350,
        overflow: "hidden",
        minWidth: 225,
      }}
    >
      {LoadingNumbers ? (
        <ActivityIndicator />
      ) : (
        <View style={{ paddingHorizontal: 8, paddingVertical: 4 }}>
          <Row>
            <Col>
              <StyledInput
                autoFocus
                value={searchText}
                onChangeText={setSearchText}
                lessPadding
                bold
                nativeID="transfer_search"
              />
            </Col>
          </Row>
          <ScrollView style={{ maxHeight: 300 }}>
            <FlatList
              data={filterNumbersBySearch}
              renderItem={({ item }) => {
                return (
                  <Option
                    label={item?.friendly_name}
                    onPress={() => onCallTransfer(item)}
                  />
                );
              }}
            />
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const IconTextButton = ({
  iconProps,
  text,
  onPress,
  containerProps,
  iconSize = 32,
}) => {
  return (
    <Col center>
      <TouchableWithoutFeedback onPress={onPress}>
        <View
          style={[
            containerProps,
            {
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <FontAwesomeIcon
            size={iconSize}
            style={{ marginTop: 4 }}
            {...iconProps}
          />
        </View>
      </TouchableWithoutFeedback>
      <Text style={{ fontSize: 8, color: palette.white, marginTop: 2 }}>
        {text}
      </Text>
    </Col>
  );
};

const NotePanel = ({ onClose }) => {
  const { activeCallId, setActiveCallNote, activeCallNote } =
    useContext(CallsContext);
  if (!activeCallId) return null;

  const { updateCallNote, saving } = useContext(ConversationContext);

  const onSave = useCallback(async () => {
    try {
      if (activeCallId) {
        await updateCallNote(activeCallId, activeCallNote);
        onClose();
      }
    } catch {}
  }, [activeCallId, activeCallNote]);

  return (
    <View
      style={{
        backgroundColor: palette.white,
        zIndex: 200,
        position: "absolute",
        top: "120%",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: palette.light_grey,
        maxWidth: 350,
        overflow: "hidden",
        minWidth: 350,
        padding: 8,
      }}
    >
      <Col rightCenter margin={5}>
        <IconButton
          onPress={onClose}
          faProIcon={faXmark}
          faPro
          width={30}
          height={30}
          color={palette.primary}
          iconColor={palette.white}
          iconSize={20}
        />
      </Col>
      <StyledInput
        value={activeCallNote}
        multiline
        autoFocus
        style={{
          flex: 1,
          fontSize: 14,
        }}
        containerStyle={{ marginVertical: 8 }}
        onChangeText={setActiveCallNote}
        nativeID="call_note"
      />
      <Row rightCenter>
        <SolidButton label={"Save"} onPress={onSave} loading={saving} />
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  side_panel__logo: {
    height: 30,
    width: 30,
    borderRadius: 6,
  },
  top_bar_container: {
    paddingHorizontal: 16,
    zIndex: 100,
    height: 60,
    flexDirection: "row",
    backgroundColor: palette.primary,
  },
});
